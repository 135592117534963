<template>
	<div class="create-profile container container-full-height container-bg-green">
		<div class="header header-large">
			<WelcomeText
				title="Create Your Profile"
				text="Free Port Welfare and Wellbeing Services from The Mission to Seafarers"
				icon="RegisterIcon"
			/>
		</div><!-- header -->
		<div class="content-wrap content-wrap-alt">
			<div class="create-profile-content">
				<div class="text-block text-block-center">
					<p class="no-space"><strong>The benefits of creating a profile</strong></p>
					<p class="no-space">This app will always be free, but creating a profile will give you greater access to the app.</p>
				</div>
				<swiper :options="swiperOption" class="swiper-box" ref="mySwiper">
					<div class="swiper-pagination" slot="pagination"></div>
					<swiper-slide class="swiper-item">
						<SendIcon class="icon" />
	                    <p class="no-space"><strong>Requests & Services</strong></p>
						<p class="no-space small">You can now pre-book a chaplain to visit, request items of shopping and contact a seafarers' centre before arriving in port</p>
	                </swiper-slide>
	                <swiper-slide class="swiper-item">
	                	<SendIcon class="icon" />
	                	<p class="no-space"><strong>Check-in</strong></p>
	                    <p class="no-space small">Sign in to the Seafarers' Centre via a QR code to access the free wi-fi</p>
	                </swiper-slide>
	                <swiper-slide class="swiper-item">
	                	<SendIcon class="icon" />
	                	<p class="no-space"><strong>Happiness index</strong></p>
	                    <p class="no-space small">Monitor your satisfaction levels against the Seafarers Happiness Index, regularly complete the survey to tell industry what you think about key issues on board</p>
	                </swiper-slide>
	                <swiper-slide class="swiper-item">
	                	<SendIcon class="icon" />
	                	<p class="no-space"><strong>Resources</strong></p>
	                    <p class="no-space small">We have a host of free mental health and wellbeing resources to help you on your voyage</p>
	                </swiper-slide>
				</swiper>
				<div class="text-block text-block-center text-block-gap">
					<p class="no-space"><strong>Creating your profile is quick and easy</strong></p>
					<p class="no-space">We will never share or sell your data.</p>
				</div>
			</div>
			<div class="flex-bottom">
				<Button routeName="/create-profile/get-started" class="btn-large btn-green" text="Get Started"></Button>
				<router-link :to="{ name: 'Home' }" class="text-link text-link-grey">I'll create a profile another time &raquo;</router-link>
			</div><!-- flex bottom -->
		</div><!-- content wrap -->
	</div>
</template>
<script>
	import { swiper, swiperSlide } from 'vue-awesome-swiper';
	import Button from '@/components/Button.vue';
	import WelcomeText from '@/components/WelcomeText.vue';
	import SendIcon from '@/assets/send.svg';
	export default {
		name: 'CreateProfile',
		components: {
	    	WelcomeText,
	    	SendIcon,
	    	Button,
	    	swiper,
        	swiperSlide
		},
		data () {
	        return {
	            swiperOption: {
	                autoplay: false,
	                speed: 750,
	                slidesPerView: 1.2,
	                centeredSlides: true,
	                spaceBetween: 15,
	                pagination: {
	                    el: '.swiper-pagination',
	                    clickable: true
	                },
	                loop: false,
	                crossFade: true,
	                keyboard: true
	            }
	        };
	    }
	};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";
@import "@/assets/scss/_global.scss";
.text-block {
	position: relative;
	margin-bottom: 25px;
	&.text-block-gap {
		margin-bottom: 70px;
	}
}
.text-link {
	font-size: 14px;
	color: $light;
	letter-spacing: -0.35px;
	display: inline-block;
	margin-top: 8px;
	&.text-link-grey {
		color: $mid;
	}
}
.swiper-box {
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-bottom: 35px;
}
.swiper-item {
    height: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    border-radius: 12px;
    background: $white;
    box-shadow: 5px 15px 15px 5px $snow;
    padding: 25px 20px;
    box-sizing: border-box;
    border: 1px solid $snow;
    flex-shrink: 0;
    svg {
    	display: block;
    	width: 36px;
    	margin-bottom: 13px;
    	g {
    		stroke: $green_base;
    		stroke-width: 2.5;
    	}
    }
    &.swiper-slide-active {
        opacity: 1!important;
    }
}
.swiper-pagination-fraction, .swiper-pagination-custom, .swiper-container-horizontal > .swiper-pagination-bullets {
    position: relative;
    bottom: auto;
    margin: 12px 0 20px 0;
}
</style>
