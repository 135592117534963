<template>
<div class="welcome-text">
	<RegisterIcon v-if="icon === 'RegisterIcon'" class="svg-icon svg-icon-register" />
	<WhiteAnchorIcon v-if="icon === 'WhiteAnchorIcon'" class="svg-icon svg-icon-white-anchor" />
	<h1>{{ title }}</h1>
	<p>{{ text }}</p>
</div><!-- welcome text -->
</template>

<script>
import WhiteAnchorIcon from '@/assets/anchor-white.svg';
import RegisterIcon from '@/assets/register.svg';
export default {
name: 'WelcomeText',
	props: {
		text: String,
		title: String,
		icon: String
	},
	components: {
		WhiteAnchorIcon,
		RegisterIcon
	}
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
	@import "@/assets/scss/_variables.scss";
	.welcome-text {
		position: relative;
		max-width: 320px;
		margin: 0 auto;
		padding-top: 40px;
		width: 100%;
		svg {
			width: 30px;
			height: auto;
			margin-bottom: 10px;
			overflow: visible;
			&.svg-icon-register {
				width: 33px;
				g {
					stroke-width: 3;
				}
			}
		}
		h1 {
			margin: 0 0 10px;
			color: $white;
			font-size: 26px;
			line-height: 31px;
		}
		p {
			margin: 0;
			color: $white;
			font-size: 17px;
			opacity: 0.8;
		}
	}
</style>
